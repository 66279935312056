
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

tr {
    cursor: pointer;
}
.text-title {
    @apply text-xl
}
.text-subtitle {
    @apply text-16px
}
.text-content {
    @apply text-14px
}
.text-body {
    @apply text-12px
}
.text-badge {
    @apply text-xxs
}

@media (min-width: 1441px) {
    .text-title {
        @apply text-24px
    }
    .text-subtitle {
        @apply text-xl
    }
    .text-content {
        @apply text-16px
    }
    .text-body {
        @apply text-14px
    }
    .text-badge {
        @apply text-12px
    }
}

.offer-hero {
    background-color: #c1f1e6;
    background-image: url('/assets/images/offer/offer-page-celebrations-desktop.svg');
    background-position: center bottom;
}

.offer-details-container {
    border-top-width: 1px;
    border-top-color: #e2e8ee;
    border-bottom-width: 1px;
    border-bottom-color: #e2e8ee;
}

.offer-outer-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 575px;
}

.box-white {
    background: #ffffff;
    border-radius: 5px;
}

.max-w-tiny {
    max-width: 50px;
}

.offer-container {
    margin-top: -10px;
    position: relative;
}

.top-image-holder {
    width: 100%;
    position: absolute;
    top: -50px;
    left: 0px;
}

.top-image-holder img {
    margin-left: auto;
    margin-right: auto;
}

.title-holder {
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #333333;
    margin-bottom: 5px;
}

.subtitle-holder {
    font-weight: 400;
    line-height: 20px;
    color: #4f4f4f;
}

.interest-text-holder {
    font-weight: 400;
    color: #84848c;
}

.interest-holder {
    font-weight: 600;
    background: #dcf8f7;
    border-radius: 37px;
    line-height: 24px;
    color: #019691;
    padding: 5px 12px 5px 17px;
    margin: 0 1px;
}

.amount-holder {
    font-weight: 700;
    letter-spacing: -1px;
    color: #009df6;
}

.max-width-300 {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
}

.info-box-holder {
    background: #f5f7f9;
    border-radius: 6px;
    margin-bottom: 5px;
}

.info-box-holder-secondary {
    background: #fefaed;
}

.emi-label {
    color: #565660;
    margin-top: 10px;
}

.emi-label:after {
    content: url('/assets/images/offer/exclamation-blue.svg');
    margin-left: 7px;
    vertical-align: sub;
}

.emi-amount-holder {
    text-align: center;
    padding: 5px;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
    0px 0px 1px rgba(130, 136, 148, 0.16);
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    font-weight: 600;
}

.max-width-450 {
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
}

.warning-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #4f4f4f;
    display: inline-block;
}

.warning-text.text-base {
    font-size: 15px;
}

.icon-holder {
    margin-right: 14px;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: auto;
}

.icon-holder.icon-exclamation-blue {
    content: url('/assets/images/offer/exclamation-blue.svg');
    vertical-align: top;
}

.icon-holder.icon-exclamation-yellow {
    content: url('/assets/images/offer/exclamation-yellow.svg');
    vertical-align: top;
}

.icon-holder.icon-comment-yellow {
    content: url('/assets/images/offer/comment-yellow.svg');
    vertical-align: sub;
}

.button.button-success {
    background: #00b0aa;
}

.button.button.button-success:hover {
    background: #3dccc7;
}

.disclaimer-holder {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #828282;
}

.congratulations .offer-hero {
    background-color: #443eff;
    background-image: none;
}

.congratulations .block-title {
    color: #84848c;
    max-width: 100%;
    height: auto;
}

.congratulations .block-content {
    color: #06051f;
    padding-bottom: 0;
}

.timeline-unit {
    font-size: 15px;
    color: #4f4f4f;
    font-weight: 400;
    line-height: 20px;
    padding-left: 30px;
    border-left-width: 1px;
    border-left-color: #e2e8ee;
    position: relative;
}

.timeline-unit:last-of-type {
    border-left: none;
    padding-bottom: 0;
}

.timeline-unit:before {
    position: absolute;
    left: -9px;
    top: 0;
    border-top-width: 3px;
    border-color: #fff;
    z-index: 1;
    background-color: #fff;
}

.timeline-unit.icon-silhouette:before {
    content: '';
    background-image: url('/assets/icons/blue-silhouette.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    width: 27px;
    height: 27px;
}

.timeline-unit.icon-inbox:before {
    content: '';
    background-image: url('/assets/icons/blue-inbox.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    width: 27px;
    height: 27px;
}

.timeline-unit.icon-file:before {
    left: -6px;
    content: '';
    background-image: url('/assets/icons/green-file.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    width: 27px;
    height: 27px;
}

.footer-warning-holder:before {
    content: url('/assets/icons/logo-light.svg');
    width: 28px;
    margin-right: 1rem;
    vertical-align: top;
}

.top-image-holder > img {
    margin: 0;
    width: 100%;
}

/************* media queries ************* /

/* mobile devices till 400px width */
@media (min-width: 300px) and (max-width: 400px) {
    .offer-container {
        padding-top: 100px;
    }

    .top-image-holder {
        top: -90px;
        left: 0;
    }
}


/* mobile devices */
@media (min-width: 401px) and (max-width: 499px) {
    .top-image-holder {
        top: -100px;
        left: 0;
    }

    .offer-container {
        padding-top: 120px;
    }
}

@media (min-width: 500px) and (max-width: 767px) {
    .top-image-holder {
        top: -120px;
    }

    .offer-container {
        padding-top: 175px;
    }
}

/* ipad devices */
@media (min-width: 768px) and (max-width: 1024px) {
    .top-image-holder {
        top: -110px;
        left: 0px;
    }

    .offer-container {
        padding-top: 170px;
    }

    .offer-outer-container {
        top: -125px;
    }

    .title-holder {
        margin-bottom: 14px;
    }

    .amount-holder {
        margin-top: 21px;
    }

    .info-box-holder {
        margin-bottom: 5px;
    }

    .emi-amount-holder {
        padding: 9px;
        font-size: 16px;
    }
}

/* desktop devices */
@media (min-width: 1025px) {
    .top-image-holder {
        top: -110px;
        left: 0px;
    }

    .offer-container {
        padding-top: 175px;
    }

    .offer-outer-container {
        margin-top: -75px;
    }

    .emi-amount-holder {
        font-size: 18px;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
    margin: 0;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}



.logo > img {
    width: 159px;
    height: auto;
}

.loader {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.application-item:hover {
    background-color: rgb(224, 224, 224);
    cursor: pointer;
}

.application-item {
    height: 56px;
}

.header {
    height: 56px;
}

.tabs, .ta {
    max-width: 1400px;
}

.user-details {
    background-color: #F5F7F9;
}

.comment > textarea {
    padding: 1rem;
    font-size: 1.3rem;
    width: 100%;
    border: solid 1px black;
    border-radius: 2px;
}

.change-stage {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.change-stage > h2 {
    font-size: 1.1rem;
    margin-right: 1rem;
}

.change-stage > select {
    padding: 1.25rem;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
}

.comment {
    margin-bottom: 2rem;
}

.save-button > button {
    background: #567AFA;
    font-size: 1.1rem;
    color: #fff;
    font-weight: bold;
    border-radius: 3px;
    height: 40px;
    width: 100px;
    text-align: center;
}

.save-like-button-small-text > button {
    background: #567AFA;
    font-size: 0.7rem;
    color: #fff;
    font-weight: bold;
    border-radius: 3px;
    height: 40px;
    min-width: 140px;
    max-width: fit-content;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
.text-red-700 {
    --text-opacity: 1!important;
    color: #c53030!important;
    color: rgba(197,48,48,var(--text-opacity))!important;
}

.border-red-400 {
    --border-opacity: 1!important;
    border-color: #fc8181!important;
    border-color: rgba(252,129,129,var(--border-opacity))!important;
}
.px-4 {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
}

.py-3 {
    padding-top: .75rem!important;
    padding-bottom: .75rem!important;
}

.logo{
    height: 30px;
}

.edit-button {
    background: #567AFA;
    color: #fff;
    font-weight: bold;
    border-radius: 3px;
    text-align: center;
    padding: 0 10px;
}

.edit-button-disabled {
    background: #cdcdcd;
    color: #8e8e8e;
    border-radius: 3px;
    text-align: center;
    padding: 0 10px;
}

.credit-table td, .credit-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

label {
    font-weight: bold;
}